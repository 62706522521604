import React from "react";
import styled from "styled-components";

export enum TextTypes {
  paragraph = "paragraph",
  title = "title",
  small = "small",
}

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.family.primary};
  color: ${(props) => props.theme.colors.text};
`;

const Paragraph = styled.p`
  font-size: ${(props) => props.theme.fonts.types.medium.size};
  font-weight: ${(props) => props.theme.fonts.types.medium.fontWeight};
  line-height: ${(props) => props.theme.fonts.types.medium.lineHeight};
  letter-spacing: ${(props) => props.theme.fonts.types.medium.letterSpacing};
`;

const Small = styled.span`
  font-size: ${(props) => props.theme.fonts.types.small.size};
  font-weight: ${(props) => props.theme.fonts.types.small.fontWeight};
  line-height: ${(props) => props.theme.fonts.types.small.lineHeight};
  letter-spacing: ${(props) => props.theme.fonts.types.small.letterSpacing};
`;

const Title = styled.h1<{ as: HeadingLevel }>`
  font-size: ${(props) => props.theme.fonts.types.large.size};
  font-weight: ${(props) => props.theme.fonts.types.large.fontWeight};
  line-height: ${(props) => props.theme.fonts.types.large.lineHeight};
  letter-spacing: ${(props) => props.theme.fonts.types.large.letterSpacing};
`;

type Props = {
  type: TextTypes;
  children: React.ReactNode;
  headingLevel?: HeadingLevel;
  style?: React.CSSProperties;
  id?: string;
  className?: string;
  // Accessibility improvements
  ariaLabel?: string;
  tabIndex?: number;
};

const Text: React.FC<Props> = (props: Props) => {
  const {
    type,
    children,
    headingLevel = 1,
    id,
    className,
    style,
    ariaLabel,
    tabIndex,
  } = props;

  return (
    <Wrapper className={className} style={{ ...style }}>
      {type === TextTypes.paragraph && (
        <Paragraph
          id={id}
          className={`${className} text-paragraph`}
          style={style}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
        >
          {children}
        </Paragraph>
      )}
      {type === TextTypes.title && (
        <Title
          as={`h${headingLevel}`} // Dynamically render heading level
          id={id}
          className={`${className} text-title`}
          style={style}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
        >
          {children}
        </Title>
      )}
      {type === TextTypes.small && (
        <Small
          id={id}
          className={`${className} text-small`}
          style={style}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
        >
          {children}
        </Small>
      )}
    </Wrapper>
  );
};

export default Text;
