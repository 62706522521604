import { FlatiniThemeType } from "@/theme";
import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

type Props = {
  children: React.ReactNode;
  theme: FlatiniThemeType;
};

const FlatiniProvider: React.FC<Props> = (props: Props) => {
  return <ThemeProvider theme={props.theme}>{props.children}</ThemeProvider>;
};

export default FlatiniProvider;
