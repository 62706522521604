export type FlatiniThemeType = {
  colors: {
    primary: string;
    secondary: string;
    background: string;
    text: string;
  };
  fonts: {
    family: {
      primary: string;
      secondary: string;
    };
    types: {
      small: {
        lineHeight: string;
        fontWeight: string;
        size: string;
        letterSpacing: string;
      };
      medium: {
        lineHeight: string;
        fontWeight: string;
        size: string;
        letterSpacing: string;
      };
      large: {
        lineHeight: string;
        fontWeight: string;
        size: string;
        letterSpacing: string;
      };
    };
  };
  sizes: {
    logo: {
      small: string;
    };
  };
};

const theme: FlatiniThemeType = {
  colors: {
    // Yellow
    primary: "#EEBA00",
    // Pink
    secondary: "#AB4369",
    background: "#0B0708",
    text: "white",
  },
  fonts: {
    family: {
      primary: "Gabarito, sans-serif",
      secondary: "Gabarito, sans-serif",
    },
    types: {
      small: {
        lineHeight: "1.6rem",
        fontWeight: "normal",
        size: "1.2rem",
        letterSpacing: "",
      },
      medium: {
        lineHeight: "1.6rem",
        fontWeight: "normal",
        size: "1.3rem",
        letterSpacing: "",
      },
      large: {
        lineHeight: "2.2rem",
        fontWeight: "bold",
        size: "2rem",
        letterSpacing: "",
      },
    },
  },
  sizes: {
    logo: {
      small: "5rem",
    },
  },
};

export default theme;
